// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    +.breadcrumb-item {
        &::before {
            font-family: "remixicon";
            content: "\ea6e"
        }
    }
}