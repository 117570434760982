//
// page-head.scss
//

.page-title-box {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding-left: 1rem;

  .page-title {
    color: $page-title-color;
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  .page-title-right {
    margin-top: 0px;
  }

  .breadcrumb {
    padding-top: 8px;
  }
}

.page-title-box-sm {
  .page-title {
    line-height: 1 !important;
    margin-bottom: 25px;
  }
  .page-title-right {
    float: right;
    margin-top: 0;
  }

  .breadcrumb {
    padding-top: 0;
    margin-top: -3px !important;
  }
}

.text-title {
  color: $text-title-color;

  &:hover {
    color: $text-title-color;
  }
}

@include media-breakpoint-down(sm) {
  .page-title-box {
    .page-title {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 70px;
    }
    .breadcrumb {
      display: none;
    }
    .page-title-right {
      display: none;
    }
  }
}

@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}
