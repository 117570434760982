//
// datepicker.scss
//

.mx-datepicker {
  width: 100% !important;
  font-family: $font-family-base !important;
}

.mx-input {
  display: block !important;
  // height: $input-height !important;
  padding: $input-padding-y $input-padding-x !important;
  font-family: $input-font-family !important;
  @include font-size($input-font-size);
  font-weight: $input-font-weight !important;
  line-height: $input-line-height !important;
  color: $input-color !important;
  background-color: $input-bg !important;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color !important;
  box-shadow: none !important;
}

.mx-calendar {
  font-family: $font-family-base !important;
}

.mx-datepicker-popup {
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15) !important;
  border: none !important;
}

.mx-panel-date td,
.mx-panel-date th {
  border-radius: 30px;
}
