//
// custom-variables.scss
//

//colors
$icon-grey: #787C84;

// Shadow
$shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
$shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);

// Background left-sidebar
$bg-leftbar: #22343D;
$bg-leftbar-light: #ffffff;

// Topbar Height
$topbar-height: 50px;

// Leftbar width
$leftbar-width: 150px;
$collapsed-leftbar-width: 70px;

// Rightbar Width
$rightbar-width: 600px;
$rightbar-bg: #ffffff;
$rightbar-title-bg: #313a46;
$rightbar-title-color: #ffffff;
$rightbar-title-btn-bg: #444e5a;
$rightbar-title-btn-color: #ffffff;
$rightbar-overlay-bg: #37404a;

// Topbar Background
$bg-topbar: $primary;
// Font weight
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 380px;

// Page title color
$page-title-color: #3F4346;

// Nav-pill background
$nav-pills-bg: #eef2f7;

// Custom-accordion
$custom-accordion-title-color: #313a46;

// Dragula demo background
$dragula-bg: #f7f9fb;

// Form wizard header background
$form-wizard-header-bg: #eef2f7;

// Text title color
$text-title-color: #6c757d;

// card Loader background
$card-loader-bg: #313a46;

// Chat widget
$chat-primary-user-bg: #fef5e4;
$chat-secondary-user-bg: #f1f3fa;

// Apex chart
$apex-grid-color: #f9f9fd;

// Hero
$hero-bg: linear-gradient(to bottom, #8669ed, #727cf5);
