//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

.form-control-sm {
    padding: 0.25rem 0.5rem;
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

.form-group {
    .custom-checkbox {
        margin-top: 5px;
    }    
}


.ck-editor__editable {
    min-height: 245px !important;
}

.ck.ck-toolbar {
    background-color: $gray-200 !important;
    border: 1px solid $gray-500 !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    border-color: $gray-500 !important;
    box-shadow: none !important;
}

.ck.ck-dropdown__panel,
.ck.ck-list {
    background: $dropdown-bg !important;
    border: 1px solid $dropdown-border-color !important;
}

.Password__field {
    display: block;
    background-color: #fff !important;
    border: 1px solid #adb5bd !important;
    border-radius: 0.2rem !important;
    font-size: 0.875rem !important;
    padding: 0.28rem 0.8rem !important;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Password__strength-meter {
    margin: 5px auto 5px !important;
}