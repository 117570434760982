//
// vue sidebar menu
//

// variables
$primary-color: $green !default;
$base-bg: $bg-leftbar !default;

$item-open-bg: $primary-color !default;

@import "~vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";

.sidebar-menu {
  background-color: #22343d;
  transition: 0.3s max-width ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .sidebar-scroll-wrapper {
    height: 100vh;
  }

  .toggle-btn {
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }
  }

  .menu-list {
    .menu-item {
      .item-link {
        flex-direction: column;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        line-height: 30px;
        text-decoration: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        z-index: 20;
        transition: 0.3s all ease;

        &.router-link-active {
          .item-icon,
          .item-title {
            color: $primary;
          }
        }

        &:hover {
          background-color: rgba(25, 38, 45, 0.5);
        }

        .item-icon {
          color: white;
          height: 30px;
          line-height: 30px;
          width: 30px;
          flex-shrink: 0;
          text-align: center;
          border-radius: 3px;
          font-size: 18px;
          transition: 0.3s all ease;
        }

        .item-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff80;
          text-align: center;
        }

        @include media-breakpoint-down(sm) {
          flex-direction: row;
          justify-content: flex-start;

          .item-icon {
            font-size: 24px;
            margin-right: 10px;
          }

          .item-title {
            font-size: 20px;
          }
        }
      }

      &.active {
        .child-menu-wrapper {
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }

        .item-link {
           background-color: rgba(25, 38, 45, 0.5);

          i, span {
            color: $primary;
          }
        }
      }

      .child-menu-wrapper {
        overflow: auto;
        min-width: 800px;
        position: absolute;
        top: 50px;
        left: 150px;
        background-color: #37464e;
        padding: 14px 25px;
        height: calc(100vh - 50px);
        display: none;

        &.active {
          display: block;
        }

        .search-group {
          margin-bottom: 40px;
        }

        .input-group {
          position: relative;

          .input-group-addon {
            position: absolute;
            top: 4px;
            left: 16px;
            font-size: 20px;
            z-index: 99;
          }

          input {
            padding: 8px 8px 8px 40px;
            color: #9599a0;
            background-color: rgba(255, 255, 255, 0.06);
            border: 1px solid rgba(223, 226, 230, 0.67);
            border-radius: 4px;
          }
        }

        .child-lists {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 90%;
          gap: 10px;
          overflow: auto;

          @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
          }

          > .child-link:last-of-type {
            margin-bottom: 10px;
          }
        }

        .child-title {
          text-transform: capitalize;
          color: white;
          font-size: 16px;
          line-height: 19.1px;
          font-weight: 700;
          margin-bottom: 20px;
        }

        .child-link {
          display: block;
          color: #ffffffb5;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;

          &:hover {
            color: $primary;
          }
        }

        .panel-link {
          margin-bottom: 8px;
        }

        .panel-menu-btn {
          color: #ffffffb5;
          font-weight: 400;
          margin-right: 10px;
        
          &:hover {
            color: $primary;
          }

          i {
            font-size: 24px !important;
          }
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            margin-bottom: 10px;

            a {
              color: #ffffffb5;
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;

              &:hover {
                color: $primary;
              }
            }
          }
        }

        @include media-breakpoint-down(lg) {
          min-width: calc(100vw - $leftbar-width);
        }

        @include media-breakpoint-down(sm) {
          min-width: 100%;
          height: calc(100% - 88px);
          top: 88px;
          left: 0;
          z-index: 99;

          .search-group {
            margin-bottom: 24px;
          }

          .child-title {
            font-size: 18px;
          }

          .child-link {
            font-size: 16px;
          }

          ul {
            li a {
              font-size: 16px;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }
  }
}

body.collapsed-side-menu {
  .item-title {
    display: none;
  }

  .sidebar-menu {
    .child-menu-wrapper {
      left: $collapsed-leftbar-width !important;

      @include media-breakpoint-down(lg) {
        min-width: calc(100vw - $collapsed-leftbar-width) !important;
      }
    }

    .toggle-btn {
      transform: rotate(-180deg);
    }
  }
}

body {
  &.toggle-side-menu {
    overflow: hidden;
  }
}
